import React from 'react'
import './footer.css'
export default function footer() {
  return (
    <div className="footer-container">
      <div className="footer-parent">
        <img src={'https://i.ibb.co/Kqq1wSR/shape-bg.png'} alt="no image" />
      </div>
    </div>
  )
}
