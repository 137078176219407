import React from 'react'

export default function AboutMeImg() {
  return (
    <div className="img-container">
      <div className="profile-img">
        <img src={"https://i.ibb.co/kgjH97T/Whats-App-Image-2023-01-03-at-21-54-42.jpg"} alt="" width={400} height={500} />
      </div>
    </div>
  )
}
